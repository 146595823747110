<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import newsItem from "@/components/news-item/news-item"
import FastImport from "@/components/FastImport"
import Sort from "@/components/sort"
import {
  checkFromData,
  formatRegionNewsList,
  cleanUpData,
  formatImportNews,
  RegionSettingSchema
} from '@/views/pages/marketing-slot/home-index/common.js'

/**
 * Starter component
 */
export default {
  name: "HomeRegion02",
  data () {
    return {
      title: "二區",
      items: [
        {
          text: "行銷版位管理",
          href: "/",
        },
        {
          text: "大首頁",
          href: "/",
        },
        {
          text: "二區",
          active: true,
        }
      ],
      fromInfo: [],
      tabIndex: 0,
      title05: '特別專題',
      title06: '特別專題2',
      title07: '影音',
      tabName: '',
      showSort: false,
      maxNewsCount: 7,
      saving: false,
      releasing: false
    };
  },
  mounted () {
    this.$root.apis.getAreaTabList(1, this.getAreaTabSuccess, this.getAreaTabError)
    this.getAreaSet(4)
  },
  watch: {
    tabIndex (_val) {
      this.getAreaSet(_val + 4)
      this.tabName = ''
    },
  },
  computed: {
    showAddButton () {
      return this.fromInfo.length < this.maxNewsCount
    }
  },
  methods: {
    getAreaTabSuccess (_response) {
      const list = _response.body.data
      this.title05 = list[0].title
      this.title06 = list[1].title
      this.title07 = list[2].title
    },
    getAreaTabError (_error) {
      console.error(_error)
    },
    changeTabName (_val) {
      if (_val.trim() === '') return
      this.tabName = _val.trim()
      switch (this.tabIndex + 4) {
        case 5:
          this.title05 = this.tabName
          break;
        case 6:
          this.title06 = this.tabName
          break;
        case 7:
          this.title07 = this.tabName
          break;
      }
    },
    getCurrentTabName () {
      switch (this.tabIndex + 4) {
        case 4:
          return '即時新聞'
        case 5:
          return this.title05
        case 6:
          return this.title06
        case 7:
          return this.title07
      }
    },
    addNewOne () {
      this.fromInfo.push({ ...RegionSettingSchema })
    },
    deleteOne (_index) {
      this.fromInfo.splice(_index, 1)
    },
    setItemData (_setItem, _index) {
      this.fromInfo.splice(_index, 1, _setItem)
    },
    getAreaSet (_regionId) {
      this.fromInfo = false
      this.$root.apis.getAreaSetting(_regionId, this.getAreaSetSuccess, this.getAreaSetError)
    },
    // 獲取區域設置成功
    getAreaSetSuccess (_response) {
      this.fromInfo = formatRegionNewsList(_response.body.data.news)
    },
    // 獲取區域設置失敗
    getAreaSetError (_error) {
      let error = _error.body.data
      this.$root.common.showErrorDialog(error)
    },

    // 請求更新分區資料
    saveRegionData () {
      const vm = this
      if (!checkFromData(vm, this.fromInfo)) {
        return
      }
      let tabName = this.getCurrentTabName()

      let setData = {
        name: tabName,
        items: cleanUpData(this.fromInfo)
      }

      this.saving = true
      this.$root.apis.saveAreaSetting(this.tabIndex + 4, setData, this.processSaveSuccess, this.processSaveError)
    },
    processSaveSuccess () {
      let tabName = this.getCurrentTabName()
      this.$root.common.showSingleNotify(tabName + "儲存成功！")
      this.saving = false
    },
    processSaveError (_error) {
      console.error('processSaveError', _error)
      this.$root.common.showErrorNotify(_error.body.data)
      this.saving = false
    },
    // 請求發布分區資料
    releaseAreaSetting () {
      this.releasing = true
      this.$root.apis.releaseAreaSetting(this.tabIndex + 4, this.processReleaseSuccess, this.processReleaseError)
    },
    processReleaseSuccess () {
      let tabName = this.getCurrentTabName()
      this.$root.common.showSingleNotify(tabName + "發布成功！")
      this.releasing = false
    },
    processReleaseError (_error) {
      let tabName = this.getCurrentTabName()
      this.$root.common.showErrorNotify(_error.body.data)
      this.releasing = false
    },
    onSortClick () {
      this.showSort = true
    },
    onSort (_sorted) {
      this.fromInfo = _sorted
      this.showSort = false
    },
    // 自動帶入
    onFastImportNewsList (_news) {
      console.log('=> 接收自動帶入的新聞清單: ', _news)
      this.fromInfo = _news.map(_article => {
        return formatImportNews(_article)
      })
    }
  },
  components: {
    Layout,
    PageHeader,
    newsItem,
    FastImport,
    Sort,
  },
};
</script>

<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  Sort(:show-sort="showSort" :data-list="fromInfo" @sort="onSort")
  .row
    .col-lg-12
      router-link(to='/')
        b-button.width-md.mb-2(variant="outline-secondary") 
          //- i.ri-arrow-left-fill.back-icon
          span 回上一層

      .card

        b-tabs.tab-color(content-class  v-model="tabIndex")
          b-tab(title="即時新聞 (左側)", active)
            .card-body
              FastImport(:region-type="4", :max-count="maxNewsCount", :show-sub-type="true" ,@import="onFastImportNewsList")
              .row
                .col
                  b-button.width-md.mb-2(variant="primary" v-b-modal.modal-sort @click="onSortClick") 編輯排序
              .row  
                .col 
                  .card.col-lg-9(v-for="(item,index) of fromInfo")
                    newsItem(
                      :key="`region0${tabIndex + 4}-NewsItemList-${index}${item.newsId}`"
                      :canUseAd="index === fromInfo.length - 1 ? true : false"
                      :regionId="tabIndex + 4"
                      :item="item" 
                      :index="index" 
                      @setItemData="setItemData"
                      @removeItem="deleteOne"
                    )
              .row
                .col
                  b-button.col-lg-9.add-btn.width-lg.mb-2(v-if="showAddButton" variant="outline-primary" @click="addNewOne") ＋新增一則 （最多{{maxNewsCount}}則） 

          b-tab(:title="`${this.title05} (左)`")
            .card-body
              FastImport(:region-type="5", :max-count="maxNewsCount", :show-sub-type="false" ,@import="onFastImportNewsList")
              .row.mb-3
                .col-12.col-lg-4
                  input.form-control(:value="tabName", @input="changeTabName($event.target.value)" type="text", placeholder="請輸入區塊標題")  
              .row
                .col
                  b-button.width-md.mb-2(variant="primary" v-b-modal.modal-sort @click="onSortClick") 編輯排序
              .row  
                .col 
                  .card.col-lg-9(v-for="(item,index) of fromInfo")
                    newsItem(
                      :key="`region0${tabIndex + 4}-NewsItemList-${item.newsId}`"
                      :canUseAd="index === fromInfo.length - 1 ? true : false"
                      :regionId="tabIndex + 4"
                      :item="item" 
                      :index="index" 
                      @setItemData="setItemData"
                      @removeItem="deleteOne"
                    )

              .row
                .col
                  b-button.col-lg-9.add-btn.width-lg.mb-2(v-if="showAddButton" variant="outline-primary" @click="addNewOne()") ＋新增一則 （最多{{maxNewsCount}}則） 



          b-tab(:title="`${this.title06} (中)`")
            .card-body
              FastImport(:region-type="6", :max-count="maxNewsCount", :show-sub-type="false" ,@import="onFastImportNewsList")
              .row.mb-3
                .col-12.col-lg-4
                  input.form-control(:value="tabName", @input="changeTabName($event.target.value)", type="text", placeholder="請輸入區塊標題")  
              .row
                .col
                  b-button.width-md.mb-2(variant="primary" v-b-modal.modal-sort @click="onSortClick") 編輯排序
              .row 
                .col 
                  .card.col-lg-9(v-for="(item,index) of fromInfo")
                    newsItem(
                      :key="`region0${tabIndex + 4}-NewsItemList-${item.newsId}`"
                      :canUseAd="index === fromInfo.length - 1 ? true : false"
                      :regionId="tabIndex + 4"
                      :item="item" 
                      :index="index" 
                      @setItemData="setItemData"
                      @removeItem="deleteOne"
                    )

              .row
                .col
                  b-button.col-lg-9.add-btn.width-lg.mb-2(v-if="showAddButton" variant="outline-primary" @click="addNewOne()") ＋新增一則 （最多{{maxNewsCount}}則） 

          b-tab(:title="`${this.title07} (右)`")
            .card-body
              FastImport(:region-type="7", :max-count="maxNewsCount", :show-sub-type="false" ,@import="onFastImportNewsList")
              .row.mb-3
                .col-12.col-lg-4
                  input.form-control(:value="tabName", @input="changeTabName($event.target.value)", type="text", placeholder="請輸入區塊標題")  
              .row
                .col
                  b-button.width-md.mb-2(variant="primary" v-b-modal.modal-sort @click="onSortClick") 編輯排序
              .row  
                .col 
                  .card.col-lg-9(v-for="(item,index) of fromInfo")
                    newsItem(
                      :key="`region0${tabIndex + 4}-NewsItemList-${item.newsId}`"
                      :canUseAd="index === fromInfo.length - 1 ? true : false"
                      :regionId="tabIndex + 4"
                      :item="item" 
                      :index="index" 
                      @setItemData="setItemData"
                      @removeItem="deleteOne"
                    )
              .row
                .col
                  b-button.col-lg-9.add-btn.width-lg.mb-2(v-if="showAddButton" variant="outline-primary" @click="addNewOne()") ＋新增一則 （最多{{maxNewsCount}}則） 
        .row.m-2 
          .col
            b-button.action-btn.width-lg.mr-1(variant="primary" @click="saveRegionData" :disabled="saving") {{ saving ? '儲存中...' : '儲存' }}
            b-button.action-btn.width-lg(variant="primary" @click="releaseAreaSetting" :disabled="releasing") {{ releasing ? '發佈中...' : '發佈' }}
</template>
<style lang="sass" scoped>
::v-deep.nav-tabs
  background: #e2e8f0
::v-deep.nav-tabs .nav-link
  font-weight: 400
  font-size: 15px
  padding: 0.8rem 2.5rem

.back-icon
  margin-right: 5px
  position: relative
  font-size: 14px
  top: 1.2px
.add-btn
  width: 100%
  border: dashed 1px

::v-deep.vue-dropzone
  padding: 0
::v-deep.dropzone-previews
  height: 170px

  .preview-img
    width: 100%
    height: 100%
    max-height: 170px
    object-fit: cover
  .img-del-btn
    position: absolute
    right: -15px
    top: -15px
    background: #1e40b0
    border-radius: 50%
    padding: 0
    height: 30px
    width: 30px
    line-height: 30px
    cursor: pointer
    i
      color: #fff
      cursor: pointer

.card
  margin-bottom: 2rem
  .form-group
    display: flex
    justify-content: space-start
    align-items: center
    flex-wrap: wrap
    gap: 14px
    .search-box
      position: relative
      #tip
        padding: 0 10px 0 35px
        background-repeat: no-repeat
        background-position: 10px 7px
      .fa-search
        position: absolute
        top: 30%
        margin: auto
    .del-btn
      flex: 1
      display: flex
      justify-content: flex-end
      align-items: center
      .btn
        padding: 0rem .3rem
        border-radius: 50px
::v-deep .tab-pane
  width: 100%

@media (max-width: 992px)
  .action-btn
    display: block
    width: 100%
    margin: 1rem 0
</style>